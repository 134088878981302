<template>
  <div>
    <section class="section first full is-vcentered center">
      <!-- is-vcentered-->
      <div class="feature__cloud"></div>
      <AnimationBg />
      <div class="container is-vcentered wrap">
        <!-- is-vcentered-->
        <div class="columns is-multiline is-mobile is-8">
          <div class="column is-hidden-mobile cid">
            <!-- Logo -->
            <img
              alt="Entfalter Logo"
              src="../../../assets.entfalter_shared/img/logo.svg"
              class="logo__left"
            />
            <!-- Punchline -->
            <h2 class="punchline" style="text-align: left; margin-left: 5px">
              Das Werkzeug für kluge Berufs- und Lebensentscheidungen
            </h2>
            <!-- Intro Anim CID -->
          </div>

          <div class="column contents">
            <!-- is-vcentered-->
            <h3>Entfaltung als Konzept</h3>
            <p class="left">
              Der Entfalter ermöglicht, sich ein Bild zu machen vom Stand der
              persönlichen Entfaltung und von den Vorstellungen, die einem zu
              der eigenen Entwicklung durch den Kopf gehen.
              <!-- Der Entfalter ermöglicht eine Momentaufnahme zum individuellen Entfaltungsprozess, in dem sich Menschen befinden und kann ihn so unterstützen. Doch was ist eigentlich gemeint, wenn wir von Entfaltung sprechen? -->
            </p>
          </div>
        </div>
      </div>
    </section>

    <section
      v-for="(p, index) in panels"
      class="panel content"
      :class="`panel-${index} ${p.className}`"
      :key="index"
    >
      <div v-if="p.className === 'left'" class="sheet">
        <div :class="'bgi __m bgi-' + (index + 1) + ' is-0-tablet'">
          <!-- <img src="img/o_aside_left.svg" alt=""> -->
        </div>

        <div class="txt left">
          <!--                    <h2><span>{{ p.title }}</span></h2>-->
          <p class="content">
            <span class="fade-in--0" v-html="p.content"></span><br />
            <small v-if="p.sub" v-html="p.sub"></small>
          </p>
        </div>
        <!--               :style="`background-image:url(${p.img})`" -->
        <div :class="'bgi __t bgi-' + (index + 1) + ' is-0-mobile'">
          <!-- <img src="img/o_aside_left.svg" alt=""> -->
        </div>
      </div>

      <div v-if="p.className === 'right'" class="sheet">
        <div :class="'bgi bgi-' + (index + 1)">
          <!-- <img src="img/o_aside_left.svg" alt=""> -->
        </div>
        <div class="txt left">
          <!--                    <h2><span>{{ p.title }}</span></h2>-->
          <p class="content">
            <span v-html="p.content"></span> <br />
            <small v-if="p.sub" v-html="p.sub"></small>
          </p>
          <Footer v-if="index + 1 == 6" :context="'inlineConcept'" />
        </div>
      </div>
    </section>

    <!--        <svg class="tri__down is-hidden-mobile" xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none">-->
    <!--            <path d="M0 0 L50 100 L100 0 Z" fill="#ffffff"></path>-->
    <!--&lt;!&ndash;                        <path d="M0 0 L0 0 L10 60 L32 70 L50 0 Z" fill="#ffffff"></path>&ndash;&gt;-->
    <!--        </svg>-->
    <br />
  </div>
</template>
<script>
/**
 * Some inspiration
 * https://tympanus.net/Development/BlockRevealers/
 * https://tympanus.net/Development/LetterEffects/
 *
 * https://tympanus.net/Tutorials/CSSMaskTransition/index2.html
 */

import AnimationBg from "../components/Animation-bg.component";
import Footer from "../components/Footer";

export default {
  name: "Concept",
  components: { AnimationBg, Footer },

  data() {
    return {
      panels: [
        {
          title: "",
          content:
            "Was dabei alles in den Blick kommt, kann in Entfaltungsprozessen zum Potenzial werden. Denn vielfach ist im Inneren manches schon angelegt, was aber Wachstumsbedingungen benötigt, um zur bereichernden Anregung zu werden und den eigenen Möglichkeiten zur Blüte zu verhelfen.",
          className: "left",
          sub: "",
          // img: '/img/slide_0.jpg'
        },
        {
          title: "",
          content:
            "Wirksame Anregungen ergeben sich aus Herausforderungen und Tätigkeiten. Als Anlässe wirken sie wie unterstützende Nährstoffe und tragen dazu bei, individuelle Fähigkeiten zu stärken und neue sich entwickeln und ausprägen zu lassen. Das gelingt im Tätig- und Wirksam-Werden.",
          className: "right",
          sub: "Grafik: Max Müller",
          // img: '../../assets.entfalter_web/img/slide_1.jpg'
        },
        {
          title: "",
          content:
            "Es ist also entfaltend, ein Gefühl für seine Potenziale zu entwickeln. Das gelingt besonders dann, wenn man sich sicher und akzeptiert fühlt. Das wird möglich in Räumen, die sich heimatlich anfühlen, in denen man sich handlungsfähig erlebt und wo sich soziale Beziehungen ergeben. Solche Umgebungen gilt es zu suchen und zu finden. Der Entfalter unterstützt dabei und hilft, eigene Potenziale zu sehen und gezielt ins Spiel bringen.",
          className: "left",
          sub: "",
        },
        {
          title: "",
          content:
            "Erkenntnisse der Neurowissenschaften bestätigen dieses Entfaltungsverständnis. Einer ihrer Vertreter, Nicolas Humphrey, erklärt: Das Subjekt ist die Summe seiner Objekte. Anders gesagt: Zu einer bestimmten Person wird man durch das, was man tut und was man an Menschen und Dingen um sich herum versammelt.",
          className: "right",
          sub: "",
        },
        {
          title: "",
          content:
            "Mit Entfaltung verbinden sich also Erfahrungen und Prozesse, die einen stärker und selbstbewusster machen. Deshalb kommt es darauf an, Aufgaben und Beziehungen zu finden, die einem liegen und die Wege dafür eröffnen, sein Potenzial einzusetzen und auszubauen. Auch das unterstützt der Entfalter. Er ermöglicht, das eigene Entfaltungskonzept zu skizzieren.",
          className: "left",
          sub: "",
        },
        {
          title: "",
          content:
            "Das Wissen um das eigene Entfaltungsprofil und -konzept schafft eine gute Grundlage für weitere Entwicklungsschritte. Diese verbinden sich mit der Suche nach Passungen, also Räumen und Tätigkeiten, die die eigene Person voranbringen. Das Matching unterstützt diese Suche. In den über 200 Matchings zeigt sich, was für Dich individuell lohnende Suchrichtungen sind, deren Verfolgung entfaltende Kraft versprechen kann. Den im Matching sich zeigenden Passungen nachzugehen, stiftet Orientierung in der Fülle der Angebote und erleichtert das Treffen von Entscheidungen. Der Entfalter hilft als ein Kompass beim Finden förderlicher Wege und will ermutigen, seine Entfaltung selbst in die Hand zu nehmen und sie selbstbestimmt zu nutzen.<br/> Wer lernt, sich selbst genauer zu sehen und besser zu verstehen, kann auch der Welt mit mehr Klarheit, Reife und neuen, guten Ideen begegnen.<br/> Verschaffe Dir einen Eindruck von der Arbeitsweise des Tools in der kostenlosen Demoversion. ",
          className: "right",
          sub: "",
        },
        {
          title: "",
          content:
            'Der Entfalter ist universell angelegt und schafft deshalb in jeder Lebensphase Klarheit, wenn man sich in Situationen der Selbstbesinnung und Entscheidungsvorbereitung befindet: wenn man vor der Wahl für eine Ausbildung, ein Studium oder eine Weiterbildung steht, auf der Suche nach neuen Tätigkeitsfeldern oder gesellschaftlichen Engagements ist. Immer hilft der Entfalter seinen Nutzer:innen, die eigene Persönlichkeit besser zu verstehen, und erlaubt, auf dieser Grundlage die Entscheidungen zu treffen, die wirklich passen können. Der Entfalter wurde basierend auf entwicklungs- und bildungstheoretischer Forschung von Prof. Dr. Renate Girmes entwickelt. Es gibt frühere Versionen des Werkzeugs. Sie werden seit über 20 Jahren in der schulischen Berufswahlunterstützung sowie in der Studienberatung und -begleitung eingesetzt. In dieser Zeit wurde das Tool stetig erweitert und verbessert. Der Entfalter ist heute ein Produkt des Unternehmens OmniMundi, dessen Angebote das Ziel haben, Bildung und Ausbildung als aktives und sinnstiftendes „Sich-Bilden“ zu ermöglichen. Mehr zum Unternehmen und seinen Produkten findet sich unter <a href="https://www.omnimundi.de" target="_blank">https://www.omnimundi.de </a> ',
          className: "left",
          sub: "Grafik: Max Müller",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>

.panel,
.sheet {
  /*min-height: 50vh;*/
}

.bgi {
  &.bgi-1 {
    background-image: url("/img/slides/concept/slide_01.jpg");

    .supports-webp & {
      background-image: url("/img/slides/concept/slide_01.jpg.webp");
    }
  }

  &.bgi-2 {
    background-image: url("/img/slides/concept/slide_02.jpg");

    .supports-webp & {
      background-image: url("/img/slides/concept/slide_02.jpg.webp");
    }
  }

  &.bgi-3 {
    background-image: url("/img/slides/concept/slide_03.jpg");

    .supports-webp & {
      background-image: url("/img/slides/concept/slide_03.jpg.webp");
    }
  }

  &.bgi-4 {
    background-image: url("/img/slides/concept/slide_04.jpg");

    .supports-webp & {
      background-image: url("/img/slides/concept/slide_04.jpg.webp");
    }
  }

  &.bgi-5 {
    background-image: url("/img/slides/concept/slide_05.jpg");

    .supports-webp & {
      background-image: url("/img/slides/concept/slide_05.jpg.webp");
    }
  }

  &.bgi-6 {
    background-image: url("/img/slides/app/slide_1.jpg");

    .supports-webp & {
      background-image: url("/img/slides/app/slide_1.jpg.webp");
    }
  }

  &.bgi-7 {
    background-image: url("/img/slides/concept/slide_06.jpg");

    .supports-webp & {
      background-image: url("/img/slides/concept/slide_06.jpg.webp");
    }
  }
}
</style>
